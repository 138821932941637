// var controller = new ScrollMagic.Controller();
// $('.app-panel').hide();
// var scene = new ScrollMagic.Scene({
// 	triggerElement : ".app-panel",
// 	duration: 1000
// })

// .on("enter", function(){
//    $('.app-panel').show(200);
// })
// .on("leave", function(){
//   // animateElem.style.background = "blue";
// })
// .addTo(controller);
if(window.AOS) AOS.init();

// initialisation materialize js
$(document).ready(function(){
  $('.sidenav').sidenav();
  $('.tabs').tabs();
  $('.modal').modal();
});
